import { setStyles } from './common'
import { closeIcon } from './icons'

export const getIsMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    const isMobileUserAgent = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)
    const isSmallScreen = document?.documentElement?.clientWidth <= 767

    return isMobileUserAgent || isSmallScreen
}

export const createDecorElement = (speed) => {
    const decorDiv = document.createElement('div')

    const decorDivStyle = {
        position: 'absolute',
        top: '-3px',
        left: '-70px',
        width: '40px',
        height: 'calc(100% + 6px)',
        backgroundColor: 'rgba(255,255,255, .3)',
        transform: 'skewX(-30deg)',
        animation: `slideDecorBoostinglead ${speed ?? '3'}s infinite linear`,
        pointerEvents: 'none',
    }

    setStyles(decorDiv, decorDivStyle)

    const styleSheet = document.createElement('style')
    styleSheet.type = 'text/css'
    styleSheet.innerHTML = `
        @keyframes slideDecorBoostinglead {
          0% {
            left: -70px;
          }
          20%, 100% {
            left: calc(100% + 40px);
          }
        }
      `
    document.head.appendChild(styleSheet)

    return decorDiv
}

// Create close btn
export const createCloseElement = async () => {
    const iconCloseElement = document.createElement('button')
    const iconCloseElementStyle = {
        position: 'absolute',
        top: '2px',
        right: '2px',
        zIndex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        padding: '0',
        lineHeight: '1',
        color: '#000',
        background: '#ffffff',
        cursor: 'pointer',
        border: 'none',
    }
    setStyles(iconCloseElement, iconCloseElementStyle)
    iconCloseElement.dataset.action = 'close'

    const resClose = await fetch(closeIcon)
    const iconClose = await resClose.text()

    iconCloseElement.innerHTML = iconClose

    return iconCloseElement
}