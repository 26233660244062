import { actions } from "./actions";
import { getConfig } from "./init";

(async function () {
  const body = document.querySelector("body");
  body.addEventListener("click", async ({ target }) => {
    if (target.dataset.view) {
      try {
        const quizConfig = await getConfig(target.dataset.view, true);

        actions["popup"].init(body, quizConfig, true);
      } catch (error) {
        console.error(error);
      }
    }
  });
})();
